import * as React from 'react';

/**
 * NOTE: When adding a new icon, add the id to this list
 * for autocomplete with in <Icon iconId="" /> with the `iconId` prop
 */
export const iconIds = [
  'check',
  'close',
  'expand-less',
  'expand-more',
  'facebook',
  'instagram',
  'phone',
  'play',
  'select-arrow',
  'twitter',
  'left-arrow',
  'right-arrow',
] as const;

export type IconId = (typeof iconIds)[number];

// This is loaded into App and ids referenced via common Icon component
export const CommonIcons = () => (
  <svg width="0" height="0" style={{ display: 'none' }}>
    <symbol viewBox="0 0 17 20" id="play">
      <path
        d="M16.5 9.134a1 1 0 0 1 0 1.732l-15 8.66A1 1 0 0 1 0 18.66V1.34A1 1 0 0 1 1.5.474l15 8.66Z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 18 14" id="check">
      <path d="M6 11.17 1.83 7 .415 8.415 6 14 18 2 16.585.585 6 11.17Z" fill="currentColor" />
    </symbol>

    <symbol viewBox="0 0 24 24" id="expand-more">
      <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" fill="currentColor" />
    </symbol>

    <symbol viewBox="0 0 24 24" id="expand-less">
      <path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z" fill="currentColor" />
    </symbol>

    <symbol viewBox="0 0 10 5" id="select-arrow">
      <path d="M0 0L5 5L10 0H0Z" fill="currentColor" />
    </symbol>

    <symbol viewBox="0 0 24 24" id="close">
      <path
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 24 24" id="instagram">
      <path
        d="M12 2.16c3.206 0 3.586.015 4.847.071 1.172.052 1.805.249 2.226.413a3.71 3.71 0 0 1 1.379.895c.421.422.68.82.895 1.378.164.422.36 1.06.412 2.227.057 1.265.07 1.645.07 4.847 0 3.206-.013 3.586-.07 4.846-.051 1.172-.248 1.805-.412 2.227a3.71 3.71 0 0 1-.895 1.378 3.69 3.69 0 0 1-1.379.895c-.421.165-1.059.361-2.226.413-1.266.056-1.645.07-4.847.07-3.206 0-3.586-.014-4.847-.07-1.172-.052-1.805-.248-2.226-.413a3.71 3.71 0 0 1-1.379-.895 3.691 3.691 0 0 1-.895-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.265-.07-1.645-.07-4.846 0-3.207.013-3.586.07-4.847.051-1.172.248-1.805.412-2.227.216-.558.478-.96.895-1.378.422-.422.82-.68 1.379-.895.421-.164 1.059-.361 2.226-.413 1.261-.056 1.64-.07 4.847-.07ZM12 0C8.742 0 8.334.014 7.055.07 5.78.127 4.903.333 4.144.628a5.857 5.857 0 0 0-2.128 1.388A5.88 5.88 0 0 0 .628 4.139C.333 4.903.127 5.775.07 7.05.014 8.334 0 8.742 0 12s.014 3.666.07 4.945c.057 1.275.263 2.152.558 2.911.31.792.717 1.463 1.388 2.128a5.866 5.866 0 0 0 2.123 1.383c.764.296 1.636.502 2.911.558 1.28.056 1.688.07 4.945.07 3.258 0 3.666-.014 4.946-.07 1.275-.056 2.151-.262 2.91-.558a5.866 5.866 0 0 0 2.124-1.383 5.866 5.866 0 0 0 1.383-2.123c.295-.764.501-1.636.558-2.911.056-1.28.07-1.688.07-4.945 0-3.258-.014-3.666-.07-4.946-.057-1.275-.263-2.151-.558-2.91a5.62 5.62 0 0 0-1.374-2.133A5.866 5.866 0 0 0 19.861.633C19.097.338 18.225.13 16.95.075 15.666.015 15.258 0 12 0Z"
        fill="currentColor"
      />
      <path
        d="M12 5.836A6.166 6.166 0 0 0 5.836 12 6.166 6.166 0 0 0 12 18.164 6.166 6.166 0 0 0 18.164 12 6.166 6.166 0 0 0 12 5.836Zm0 10.162A3.999 3.999 0 1 1 12.001 8 3.999 3.999 0 0 1 12 15.998ZM19.847 5.592a1.44 1.44 0 1 1-2.879 0 1.44 1.44 0 0 1 2.879 0Z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 24 24" id="phone">
      <path
        d="M6.62 10.79c1.44 2.83 3.76 5.15 6.59 6.59l2.2-2.2c.28-.28.67-.36 1.02-.25 1.12.37 2.32.57 3.57.57a1 1 0 0 1 1 1V20a1 1 0 0 1-1 1A17 17 0 0 1 3 4a1 1 0 0 1 1-1h3.5a1 1 0 0 1 1 1c0 1.25.2 2.45.57 3.57.11.35.03.74-.25 1.02l-2.2 2.2Z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 24 25" id="facebook">
      <g>
        <path
          d="M24 12.073c0-6.628-5.373-12-12-12s-12 5.372-12 12c0 5.99 4.388 10.954 10.125 11.854v-8.386H7.078v-3.468h3.047V9.429c0-3.008 1.792-4.669 4.533-4.669 1.312 0 2.686.235 2.686.235v2.953H15.83c-1.491 0-1.956.925-1.956 1.875v2.25h3.328l-.532 3.468h-2.796v8.386C19.612 23.027 24 18.062 24 12.073Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath>
          <path transform="translate(0 .073)" d="M0 0h24v23.854H0z" />
        </clipPath>
      </defs>
    </symbol>

    <symbol viewBox="0 0 24 24" id="twitter">
      <path
        d="M7.55 21.75c9.055 0 14.008-7.504 14.008-14.008 0-.211-.004-.427-.014-.638A10 10 0 0 0 24 4.555c-.898.4-1.85.66-2.826.773a4.95 4.95 0 0 0 2.165-2.722A9.897 9.897 0 0 1 20.213 3.8a4.93 4.93 0 0 0-8.394 4.49A13.984 13.984 0 0 1 1.673 3.15a4.93 4.93 0 0 0 1.523 6.57 4.93 4.93 0 0 1-2.23-.614v.06a4.922 4.922 0 0 0 3.95 4.829 4.893 4.893 0 0 1-2.221.084 4.934 4.934 0 0 0 4.597 3.422A9.875 9.875 0 0 1 0 19.538a13.969 13.969 0 0 0 7.55 2.212Z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 40 40" id="left-arrow">
      <path
        d="M24.3337 30L26.667 27.6667L19.0004 20.0001L26.667 12.3335L24.3337 10.0002L14.3338 20.0001L24.3337 30Z"
        fill="currentColor"
      />
    </symbol>

    <symbol viewBox="0 0 40 40" id="right-arrow">
      <path
        d="M15.6668 10L13.3335 12.3333L21.0001 19.9999L13.3335 27.6665L15.6668 29.9998L25.6667 19.9999L15.6668 10Z"
        fill="currentColor"
      />
    </symbol>
  </svg>
);
