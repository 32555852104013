const SITE_WIDTH = '1344px';

// TODO: check with percy how different this would look with say... 53ch
const MEASURE = '700px';

const BREAKPOINTS = {
  xs: '0px',
  s: '768px',
  m: '1024px',
  l: '1440px',
  xl: '1600px',
  xxl: '1920px',
};

// usage: @media ${theme.media.min.s} { /* css */}
const MEDIA = {
  min: {
    s: `(min-width: ${BREAKPOINTS.s})`,
    m: `(min-width: ${BREAKPOINTS.m})`,
    l: `(min-width: ${BREAKPOINTS.l})`,
    xl: `(min-width: ${BREAKPOINTS.xl})`,
    xxl: `(min-width: ${BREAKPOINTS.xxl})`,
  },
  max: {
    s: `(max-width: ${BREAKPOINTS.s})`,
    m: `(max-width: ${BREAKPOINTS.m})`,
    l: `(max-width: ${BREAKPOINTS.l})`,
    xl: `(max-width: ${BREAKPOINTS.xl})`,
    xxl: `(max-width: ${BREAKPOINTS.xxl})`,
  },
};

/* See typography file for vars */
const FONT_SIZES = {
  xs: 'var(--step--2)',
  s: 'var(--step--1)',
  sm: 'var(--step--05)',
  m: 'var(--step-0)',
  l: 'var(--step-1)',
  xl: 'var(--step-2)',
  xxl: 'var(--step-3)',
  xxxl: 'var(--step-4)',
};

/* See typography file for vars */
const SCALE = {
  xxxs: 'var(--space-3xs)',
  xxs: 'var(--space-2xs)',
  xs: 'var(--space-xs)',
  s: 'var(--space-s)',
  m: 'var(--space-m)',
  l: 'var(--space-l)',
  xl: 'var(--space-xl)',
  xxl: 'var(--space-2xl)',
  xxxl: 'var(--space-3xl)',

  // One-up pairs - interpolated from x to y as screen size increases.
  // E.g. mToL = from medium on small screens to large on larger screens
  xxxsToXxs: 'var(--space-3xs-2xs)',
  xxsToXs: 'var(--space-2xs-xs)',
  xsToS: 'var(--space-xs-s)',
  sToM: 'var(--space-s-m)',
  mToL: 'var(--space-m-l)',
  lToXl: 'var(--space-l-xl)',
  xlToXxl: 'var(--space-xl-2xl)',
  xxlToXxxl: 'var(--space-2xl-3xl)',
};

const SPACE = {
  ...SCALE,
  gutters: SCALE.sToM,
  section: SCALE.xlToXxl,
  measure: MEASURE,
  siteWidth: SITE_WIDTH,
};

// Stile color guide
// https://zeroheight.com/75ecd6742/p/994df5-colour/b/29a6d9
const BASE_COLORS = {
  green1: '#00E65A',
  green2: '#00CB50',
  green3: '#00A541',

  blue1: '#2469FF',
  blue2: '#1D54C9',
  blue3: '#12347F',
  blue4: '#0D275B',
  blue5: '#0B012F',

  teal1: '#5BDBD5',
  teal2: '#35C3BD',
  teal3: '#0C9D97',
  teal4: '#047A75',
  teal5: '#005E5B',

  orange1: '#FF9817',
  orange2: '#FF5C3B',
  orange3: '#CC4B31',

  cherry1: '#D13565',
  cherry2: '#AD2B53',
  cherry3: '#801F3C',

  grey1: '#f5f5f5',
  grey2: '#eeeeee',
  grey3: '#e0e0e0',
  grey4: '#bdbdbd',
  grey5: '#9e9e9e',
  grey6: '#757575',
  grey7: '#616161',
  grey8: '#424242',
  grey9: '#212121',

  white: '#fefefe',
  beige: '#faf8f7',
};

export const COLORS = {
  ...BASE_COLORS,
  // Common usage
  brand: BASE_COLORS.green1,
  dark: BASE_COLORS.grey8,
  light: BASE_COLORS.grey1,
  error: BASE_COLORS.cherry1,
  stileXDark: BASE_COLORS.blue5,
};

const BORDERS = {
  xs: '1px solid',
  s: '2px solid',
};

const BORDER_RADIUS = {
  s: '4px',
  m: '8px',
  l: '16px',
};

const SHADOWS = {
  drop: `0px 40px 120px rgba(63, 63, 114, 0.3)`,
  bottomLight: `0px 1.3px 5.3px rgba(0, 0, 0, 0.032),
      0px 4.5px 17.9px rgba(0, 0, 0, 0.048), 0px 20px 80px rgba(0, 0, 0, 0.08)`,
  bottomExtraLight: `0px 1px 4px rgba(0, 0, 0, 0.01),
      0px 2px 12px rgba(0, 0, 0, 0.02), 0px 6px 36px rgba(0, 0, 0, 0.04)`,
};

const TRANSITIONS = {
  short: '0.2s ease-in-out',
  long: '0.5s ease-in-out',
};

export const theme = {
  borderRadius: BORDER_RADIUS,
  borders: BORDERS,
  breakpoints: BREAKPOINTS,
  colors: COLORS,
  media: MEDIA,
  space: SPACE,
  fontSizes: FONT_SIZES,
  shadows: SHADOWS,
  transitions: TRANSITIONS,
} as const;
