import * as React from 'react';

import { Script } from 'gatsby';
import { Minimatch } from 'minimatch';
import invariant from 'tiny-invariant';

import { stripIndent } from 'common-tags';

type GoogleGtagProps = {
  trackingIds: (string | undefined)[];
  config?: {
    exclude?: string[];
    respectDNT?: boolean;
  };
  gtagConfig?: Gtag.ConfigParams;
};

// Mostly cannibalized from https://github.com/gatsbyjs/gatsby/blob/master/packages/gatsby-plugin-google-gtag/
export function GoogleGtag({ trackingIds, config = {}, gtagConfig = {} }: GoogleGtagProps) {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  invariant(trackingIds.every(Boolean), 'Google tracking ids missing or undefined');

  // Prevent duplicate or excluded pageview events being emitted on initial load of page by the `config` command
  // https://developers.google.com/analytics/devguides/collection/gtagjs/#disable_pageview_tracking
  // We still trigger page views on route change with helper function triggerGtagView() below
  gtagConfig.send_page_view = false;

  const firstTrackingId = trackingIds[0];

  const excludeGtagPaths: RegExp[] = [];

  if (typeof config.exclude !== `undefined`) {
    config.exclude.map((exclude) => {
      const mm = new Minimatch(exclude);
      const regExp = mm.makeRe();
      if (regExp) {
        excludeGtagPaths.push();
      }
    });
  }

  return (
    <React.Fragment key="google-gtag-scripts">
      <Script
        id="google-gtag-load"
        async
        src={`https://www.googletagmanager.com/gtag/js?id=${firstTrackingId}`}
      />

      <Script
        id="google-gtag-script"
        dangerouslySetInnerHTML={{
          __html: stripIndent`
            ${
              excludeGtagPaths.length
                ? `window.excludeGtagPaths=[${excludeGtagPaths.join(`,`)}];`
                : ``
            }
            if(${
              config.respectDNT
                ? `!(navigator.doNotTrack == "1" || window.doNotTrack == "1")`
                : `true`
            }) {
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer && window.dataLayer.push(arguments);}
              gtag('js', new Date());

              ${trackingIds
                .map(
                  (trackingId) => `gtag('config', '${trackingId}', ${JSON.stringify(gtagConfig)});`
                )
                .join('')}
            }
            `,
        }}
      />
    </React.Fragment>
  );
}

// Appease typescript since these properties are added in the inline scripts above
interface CustomWindow extends Window {
  gtag?: Gtag.Gtag;
  excludeGtagPaths?: RegExp[];
}
declare let window: CustomWindow;

/** Needs to be triggered in gatsby-browser when route changes */
export function triggerGtagView(location: Location) {
  if (process.env.NODE_ENV !== 'production' || typeof window.gtag !== 'function') {
    return;
  }

  const pathIsExcluded =
    location &&
    typeof window.excludeGtagPaths !== `undefined` &&
    window.excludeGtagPaths.some((regex: RegExp) => regex.test(location.pathname));

  if (pathIsExcluded) return null;

  // wrap inside a timeout to make sure react-helmet is done with its changes (https://github.com/gatsbyjs/gatsby/issues/11592)
  const sendPageView = () => {
    const pagePath = location ? location.pathname + location.search + location.hash : undefined;
    window.gtag?.(`event`, `page_view`, { page_path: pagePath });
  };

  if (`requestAnimationFrame` in window) {
    requestAnimationFrame(() => {
      requestAnimationFrame(sendPageView);
    });
  } else {
    // simulate 2 rAF calls
    setTimeout(sendPageView, 32);
  }

  return null;
}
