import { createMedia } from '@artsy/fresnel';

import { theme } from '../../../../styles/2020/theme';

// extract widths as numbers (1200), rather than px strings ('1200px')
const BREAKPOINTS = Object.entries(theme.breakpoints).reduce<Record<string, number>>(
  (acc, [k, v]) => {
    acc[k] = parseInt(v, 10);
    return acc;
  },
  {}
);

const MatchMedia = createMedia({
  breakpoints: BREAKPOINTS,
  interactions: {
    hover: `not all and (hover:hover)`,
  },
});

/**
 * <Media /> is used to dynamically display elements dependent on media queries.
 *
 * This works with SSR by rendering all elements on the server,
 * and then hiding any unmatched elements using CSS media queries.
 *
 * This sidesteps many issues caused by using hooks or other client-based conditionals
 * that do not play nicely with SSR (e.g. react-use etc).
 *
 * See https://github.com/artsy/fresnel for documentation
 */
export const Media = MatchMedia.Media;
export const MediaContextProvider = MatchMedia.MediaContextProvider;
export const mediaStyles = MatchMedia.createMediaStyle();
