import * as React from 'react';

import { Script } from 'gatsby';
import invariant from 'tiny-invariant';

import { stripIndent } from 'common-tags';

type LinkedInInsightProps = { partnerId?: string };

export function LinkedInInsight({ partnerId }: LinkedInInsightProps) {
  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  invariant(partnerId, 'LinkedIn Insight partner id was not provided');

  return (
    <React.Fragment key="linkedin-insight-scripts">
      <Script
        id="linkedin-insight-config"
        dangerouslySetInnerHTML={{
          __html: stripIndent`
                _linkedin_partner_id = "${partnerId}";
                window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
                window._linkedin_data_partner_ids.push(_linkedin_partner_id);`,
        }}
      />
      <Script
        id="linkedin-insight-script"
        dangerouslySetInnerHTML={{
          __html: stripIndent`
                (function () {
                    var s = document.getElementsByTagName("script")[0];
                    var b = document.createElement("script");
                    b.type = "text/javascript";
                    b.async = true;
                    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
                    s.parentNode.insertBefore(b, s);
                })();`,
        }}
      />
      <noscript>
        <img
          height="1"
          width="1"
          style={{ display: `none` }}
          alt=""
          src={`https://dc.ads.linkedin.com/collect/?pid=${partnerId}&fmt=gif`}
        />
      </noscript>
    </React.Fragment>
  );
}
