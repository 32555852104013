import { rgba } from 'polished';
import { css } from 'styled-components';

import { theme } from './theme';

/* NOTE: Reasoning behind fluid scales */
/* https://utopia.fyi/blog/designing-with-fluid-type-scales */
/* https://utopia.fyi/blog/painting-with-a-fluid-space-palette */

/* NOTE: To change these to a new scale, use the calculator and select "use clamp" */
/* https://utopia.fyi/type/calculator?c=320,15,1.2,1440,19,1.333,5,2,&s=0.75|0.5|0.25|0.133,1.5|2|3|4|6,s-l */
export const fontSizeVars = css`
  :root {
    --step--2: clamp(0.65rem, calc(0.65rem + 0.02vw), 0.67rem);
    --step--1: clamp(0.78rem, calc(0.75rem + 0.16vw), 0.89rem);
    // step--05 added manually (midpoint between steps 0 and 1)
    // will not be generated by above calculator!
    --step--05: clamp(0.86rem, calc(0.81rem + 0.26vw), 1.04rem);
    --step-0: clamp(0.94rem, calc(0.87rem + 0.36vw), 1.19rem);
    --step-1: clamp(1.13rem, calc(0.99rem + 0.65vw), 1.58rem);
    --step-2: clamp(1.35rem, calc(1.13rem + 1.09vw), 2.11rem);
    --step-3: clamp(1.62rem, calc(1.28rem + 1.7vw), 2.81rem);
    --step-4: clamp(1.94rem, calc(1.43rem + 2.58vw), 3.75rem);
    --step-5: clamp(2.33rem, calc(1.57rem + 3.81vw), 5rem);
  }
`;

/* NOTE: To change these to a new scale, use the calculator and select "use clamp" */
/* Ideally the ratios at least should match those used for font sizes above */
/* https://utopia.fyi/space/calculator?c=320,15,1.2,1440,18,1.33,5,2,&s=0.75|0.5|0.25,1.5|2|3|4|6,xl-3xl */
export const spaceVars = css`
  :root {
    --space-3xs: clamp(0.25rem, calc(0.23rem + 0.09vw), 0.31rem);
    --space-2xs: clamp(0.5rem, calc(0.45rem + 0.27vw), 0.69rem);
    --space-xs: clamp(0.75rem, calc(0.68rem + 0.36vw), 1rem);
    --space-s: clamp(1rem, calc(0.91rem + 0.45vw), 1.31rem);
    --space-m: clamp(1.5rem, calc(1.36rem + 0.71vw), 2rem);
    --space-l: clamp(2rem, calc(1.82rem + 0.89vw), 2.63rem);
    --space-xl: clamp(3rem, calc(2.73rem + 1.34vw), 3.94rem);
    --space-2xl: clamp(4rem, calc(3.64rem + 1.79vw), 5.25rem);
    --space-3xl: clamp(6rem, calc(5.46rem + 2.68vw), 7.88rem);

    /* One-up pairs */
    --space-3xs-2xs: clamp(0.25rem, calc(0.13rem + 0.63vw), 0.69rem);
    --space-2xs-xs: clamp(0.5rem, calc(0.36rem + 0.71vw), 1rem);
    --space-xs-s: clamp(0.75rem, calc(0.59rem + 0.8vw), 1.31rem);
    --space-s-m: clamp(1rem, calc(0.71rem + 1.43vw), 2rem);
    --space-m-l: clamp(1.5rem, calc(1.18rem + 1.61vw), 2.63rem);
    --space-l-xl: clamp(2rem, calc(1.45rem + 2.77vw), 3.94rem);
    --space-xl-2xl: clamp(3rem, calc(2.36rem + 3.21vw), 5.25rem);
    --space-2xl-3xl: clamp(4rem, calc(2.89rem + 5.54vw), 7.88rem);
  }
`;

/* https://kittygiraudel.com/2020/05/18/using-calc-to-figure-out-optimal-line-height */
export const lineHeight = css`
  &,
  & * {
    line-height: calc(2px + 2.5ex + 2px);
  }
`;
export const headingLineHeight = css`
  &,
  & * {
    line-height: 2.3ex;
  }
`;

export const linkHoverState = css`
  text-decoration: underline;
  text-decoration-line: underline;
  text-decoration-color: ${rgba(theme.colors.brand, 0)};
  transition: text-decoration-color ${theme.transitions.short};

  & img {
    transition: filter ${theme.transitions.short};
  }

  & svg {
    transition: transform ${theme.transitions.short};
  }

  &:hover {
    text-decoration-color: ${rgba(theme.colors.brand, 1)};

    & img {
      filter: brightness(1.1);
    }

    & svg {
      transform: scale(1.1);
    }
  }
`;

export const unstyledLink = css`
  color: inherit;
  text-decoration: none;
`;

export const hoverLink = css`
  color: inherit;
  text-decoration: none;
  ${linkHoverState};
`;

export const inlineLink = css`
  color: ${theme.colors.grey9};
  font-weight: 400;
  text-decoration: underline 0.12em ${rgba(theme.colors.brand, 0.6)};
  /* Safari missing styles without explicit values */
  text-decoration-line: underline;
  text-decoration-color: ${rgba(theme.colors.brand, 0.6)};
  text-decoration-thickness: 0.12em;
  transition: text-decoration-color ${theme.transitions.short};

  &:hover {
    text-decoration-color: ${rgba(theme.colors.brand, 1)};
  }
`;

export const inheritLink = css`
  ${inlineLink}
  color: inherit;
  font-weight: inherit;
`;

export const greyLink = css`
  color: ${theme.colors.grey8};
  font-weight: 400;
  text-decoration: underline 0.12em ${rgba(theme.colors.grey8, 0.6)};
  /* Safari missing styles without explicit values */
  text-decoration-line: underline;
  text-decoration-color: ${rgba(theme.colors.grey8, 0.6)};
  text-decoration-thickness: 0.12em;
  transition: text-decoration-color ${theme.transitions.short};

  &:hover {
    text-decoration-color: ${rgba(theme.colors.grey8, 1)};
  }
`;

export const text = css`
  ${lineHeight}
  font-size: ${theme.fontSizes.m};
  font-weight: 400;
`;

export const heading = css`
  ${headingLineHeight}
  font-family: 'Boing', sans-serif;
  font-size: ${theme.space.l};
  font-weight: 500;
`;

export const inputStyle = css`
  font-weight: 500;
  border: 1px solid;
  border-color: ${theme.colors.grey3};
  padding: ${theme.space.xs};
  border-radius: ${theme.borderRadius.s};
  transition: color ${theme.transitions.short}, opacity ${theme.transitions.short},
    border-color ${theme.transitions.short};

  &::placeholder {
    color: ${theme.colors.grey8};
    font-weight: 400;
    opacity: 0.5;
  }

  &:focus-visible {
    outline: none;
    box-shadow: none;
    border-color: ${theme.colors.brand};
  }

  &:disabled {
    opacity: 0.5;
  }
`;
