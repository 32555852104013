import * as React from 'react';

import { Script } from 'gatsby';
import invariant from 'tiny-invariant';

import { stripIndent } from 'common-tags';

type FacebookPixelProps = { pixelId?: string };

export function FacebookPixel({ pixelId }: FacebookPixelProps) {
  invariant(pixelId, 'Facebook pixel id was not provided');

  if (process.env.NODE_ENV !== 'production') {
    return null;
  }

  return (
    <Script
      id="facebook-pixel-script"
      dangerouslySetInnerHTML={{
        __html: stripIndent`
          !function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;
          n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window,
          document,'script','https://connect.facebook.net/en_US/fbevents.js');
          fbq('init', '${pixelId}');
          fbq('track', 'PageView');`,
      }}
    />
  );
}

// Appease typescript since these properties are added in the inline scripts above
interface CustomWindow extends Window {
  fbq?: (command: string, value: string) => void;
}
declare let window: CustomWindow;

/** Needs to be triggered in gatsby-browser when route changes */
export function triggerPixelView() {
  if (process.env.NODE_ENV !== 'production' || typeof window.fbq !== 'function') {
    return;
  }
  window.fbq('track', 'ViewContent');
}
