export const SITE_TITLE = 'Stile Education';
export const SITE_URL = 'https://stileeducation.com';

// NOTE: no trailing slash!
export const LOCALE_URLS = {
  'en-au': `${SITE_URL}/au`,
  'en-us': `${SITE_URL}/us`,
  'en-ca': `${SITE_URL}/us`,
  en: `${SITE_URL}/au`, // language fallback when region unspecified
};

export const CONTACT = {
  au: {
    phone: { url: 'tel:+611300918292', text: '1300 918 292' },
    email: { url: 'mailto:help@stileeducation.com', text: 'help@stileeducation.com' },
    address: {
      descriptive:
        'Stile HQ is located at 128 Exhibition Street, Melbourne, Australia, on the traditional lands of the Boon Wurrung and Woiwurrung (Wurundjeri) peoples of the Kulin Nation. We acknowledge that sovereignty was never ceded and pay our respects to Elders past, present and future.',
      map: {
        url: 'https://goo.gl/maps/4R1emL2kDQNwNn7b8',
        text: ['Level 5, 128 Exhibition St,', 'Melbourne, VIC 3000 Australia'],
      },
      postal: [
        'Stile Education Pty Ltd (ACN 159 359 681)',
        'Level 5, 128 Exhibition Street',
        'Melbourne VIC 3000',
        'Australia',
      ],
    },
  },
  us: {
    phone: { url: 'tel:+13233017470', text: '323 301 7470' },
    email: { url: 'mailto:hello@stileeducation.com', text: 'hello@stileeducation.com' },
    address: {
      descriptive:
        'Stile’s office is located on the land we now call Portland, Oregon. We recognize and honor the Indigenous peoples on whose ancestral lands we live and work. These include the Willamette Tumwater, Clackamas, Kathlamet, Molalla, Multnomah and Watlala Chinook Peoples and the Tualatin Kalapuya who today are part of the Confederated Tribes of Grand Ronde, and many other Indigenous communities who made their homes along the Columbia River.',
      map: {
        url: 'https://goo.gl/maps/sGGwUqmzBatn3DBk8',
        text: ['811 SW 6th Ave, Suite 1000', 'Portland, OR 97204, USA'],
      },
      postal: [
        'Stile Education Inc.',
        '811 Southwest 6th Avenue',
        'Suite 1000',
        'Portland, OR 97204',
        'United States of America',
      ],
    },
  },
  nz: { phone: { url: 'tel:+64800000252', text: '0800 000 252' } },
  international: { phone: { url: 'tel:+61385393289', text: '+61 3 8539 3289' } },
  email: {
    help: { url: 'mailto:help@stileeducation.com', text: 'help@stileeducation.com' },
    privacy: { url: 'mailto:privacy@stileeducation.com', text: 'privacy@stileeducation.com' },
    concierge: { url: 'mailto:concierge@stileeducation.com', text: 'concierge@stileeducation.com' },
    plteam: { url: 'mailto:plteam@stileeducation.com', text: 'plteam@stileeducation.com' },
    educators: { url: 'mailto:educators@stileeducation.com', text: 'educators@stileeducation.com' },
    community: { url: 'mailto:community@stileeducation.com', text: 'community@stileeducation.com' },
  },
};

export const SHOP_URL = 'https://shop.stileeducation.com';
export const BLOG_URL = 'https://blog.stileeducation.com';
export const FACEBOOK_URL = 'https://www.facebook.com/StileEducation';
export const INSTAGRAM_URL = 'https://www.instagram.com/stileeducation';
export const TWITTER_URL = 'https://www.twitter.com/stileeducation';

export const APP_STORE_URL = 'https://apps.apple.com/us/app/stile-x/id1574262193';
export const GOOGLE_PLAY_STORE_URL =
  'https://play.google.com/store/apps/details?id=com.stileeducation.StudentCompanion';

export const LINKEDIN_INSIGHT_PARTNER_ID = '3840740';
export const FACEBOOK_PIXEL_ID = '2940891652811803';
export const GOOGLE_UA_ID = 'G-XN4N423KK3';
export const GOOGLE_ADS_ID = 'AW-10860849256';
export const FACEBOOK_DOMAIN_VERIFICATION = '33snz4uc0d4ctmoa5yoxlvdfjawk4w';
export const GOOGLE_SITE_VERIFICATION = 'GShiIKL1Mk-_fq34IN-tkK_w8zw0ZG8aIK6ExVYks5M';
export const MAPBOX_API_KEY =
  'pk.eyJ1Ijoic2Vhbmh1cmxleTAxIiwiYSI6ImNrNnU1dnNrajA2aWQzcW53MHVhMjU2YXUifQ.7PNPbv3ipbsFfULObNVJDQ';

export const STILE_APP_URL = 'https://stileapp.com/login';
export const TRIAL_PAGE_URL = '/set-up-trial/';

export const PAGE_SECTIONS: Record<string, string> = {
  'what-is-stile': 'What is Stile?',
  'why-choose-stile': 'Why choose Stile?',
  'who-we-are': 'Who we are',
  resources: 'Resources',
};

export const OTHER_SITE_LINKS = [
  { text: 'Privacy', url: '/other/privacy/' },
  { text: 'Security', url: '/other/security/' },
  { text: 'Terms', url: '/other/terms/' },
  { text: 'Blog', url: BLOG_URL },
  { text: 'Resources', url: '/resources/' },
  { text: 'Shop', url: SHOP_URL },
];

// user region is set in a cookie by our AWS `origin response` lambda
export const USER_REGION_COOKIE_NAME = 'urr';
