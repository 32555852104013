import '@reach/skip-nav/styles.css';

import * as React from 'react';

import type { WrapRootElementBrowserArgs, WrapRootElementNodeArgs } from 'gatsby';
import { QueryClient, QueryClientProvider } from 'react-query';

// import { ReactQueryDevtools } from 'react-query/devtools';
import { MediaContextProvider } from '../components/2020/layout/Media';

import { CommonIcons } from '../../assets/icons/CommonIcons';

const queryClient = new QueryClient();

export function WrapRootElement({ element }: WrapRootElementBrowserArgs | WrapRootElementNodeArgs) {
  return (
    <QueryClientProvider client={queryClient}>
      <MediaContextProvider>
        <CommonIcons />
        {element}
      </MediaContextProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
}
