import type { RouteUpdateArgs } from 'gatsby';

import { triggerPixelView } from '../src/gatsby/FacebookPixel';
import { triggerGtagView } from '../src/gatsby/GoogleGtag';
import { WrapPageElement } from '../src/gatsby/WrapPageElement';
import { WrapRootElement } from '../src/gatsby/WrapRootElement';
import '../src/styles/2020/fonts.css';
import '../src/styles/2020/percy.css';

export const wrapPageElement = WrapPageElement;
export const wrapRootElement = WrapRootElement;

export const onRouteUpdate = ({ location }: RouteUpdateArgs) => {
  triggerGtagView(location);
  triggerPixelView();
};
