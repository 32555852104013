import { css } from 'styled-components';

import { theme } from './theme';
import { inlineLink, lineHeight } from './typography';

/*
  Prose is used for longform text we have little control over
  (e.g. job application html from workable)
*/
const proseVars = css`
  :root {
    --prose-body: ${theme.colors.grey8};
    --prose-headings: ${theme.colors.grey8};
    --prose-lead: ${theme.colors.grey6};
    --prose-links: ${theme.colors.grey9};
    --prose-bold: ${theme.colors.grey9};
    --prose-counters: ${theme.colors.grey7};
    --prose-bullets: ${theme.colors.grey7};
    --prose-hr: ${theme.colors.grey2};
    --prose-quotes: ${theme.colors.grey8};
    --prose-quote-borders: ${theme.colors.grey2};
    --prose-captions: ${theme.colors.grey7};
    --prose-code: ${theme.colors.grey9};
    --prose-pre-code: ${theme.colors.grey2};
    --prose-pre-bg: ${theme.colors.grey8};
    --prose-th-borders: ${theme.colors.grey3};
    --prose-td-borders: ${theme.colors.grey2};
  }
`;

const proseBase = css`
  color: var(--prose-body);

  .lead {
    color: var(--prose-lead);
  }

  dfn,
  .bold-italic {
    font-weight: bold;
    font-style: italic;
  }

  blockquote,
  dl,
  dd,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  hr,
  figure,
  p,
  pre {
    margin: 0;
  }

  a {
    ${inlineLink}
    color: var(--prose-links);
  }

  strong {
    color: var(--prose-bold);
    font-weight: 500;
  }

  ol {
    list-style-type: decimal;
  }

  .large-markers > li::marker {
    font-size: 1.5em;
    color: var(--prose-headings);
    font-weight: 600;
  }

  ol[type='A'] {
    list-style-type: upper-alpha;
  }

  ol[type='a'] {
    list-style-type: lower-alpha;
  }

  ol[type='I'] {
    list-style-type: upper-roman;
  }

  ol[type='i'] {
    list-style-type: lower-roman;
  }

  ol[type='1'] {
    list-style-type: decimal;
  }

  ul {
    list-style-type: disc;
  }

  ol > li::marker {
    font-weight: 400;
    color: var(--prose-counters);
  }

  ul > li::marker {
    color: var(--prose-bullets);
  }

  hr {
    border-color: var(--prose-hr);
    border-top-width: 1;
  }

  blockquote {
    font-weight: 500;
    font-style: italic;
    color: var(--prose-quotes);
    border-left-style: solid;
    border-left-width: 0.25rem;
    border-left-color: var(--prose-quote-borders);
    quotes: '“' '”' '‘' '’';
  }

  blockquote p:first-of-type::before {
    content: open-quote;
  }

  blockquote p:last-of-type::after {
    content: close-quote;
  }

  h1 {
    color: var(--prose-headings);
    font-weight: 600;
  }

  h1 strong {
    font-weight: 900;
  }

  h2 {
    color: var(--prose-headings);
    font-weight: 600;
  }

  h2 strong {
    font-weight: 600;
  }

  h3 {
    color: var(--prose-headings);
    font-weight: 500;
  }

  h3 strong {
    font-weight: 600;
  }

  h4,
  h5,
  h6 {
    color: var(--prose-headings);
    font-weight: 500;
  }

  h4 strong,
  h5 strong,
  h6 strong {
    font-weight: 600;
  }

  figcaption {
    color: var(--prose-captions);
  }

  code {
    color: var(--prose-code);
    font-weight: 500;
  }

  code::before {
    content: '\`';
  }

  code::after {
    content: '\`';
  }

  a code {
    color: var(--prose-links);
  }

  pre {
    color: var(--prose-pre-code);
    background-color: var(--prose-pre-bg);
    overflow-x: auto;
    font-weight: 400;
  }

  pre code {
    background-color: transparent;
    border-width: 0;
    border-radius: 0;
    padding: 0;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    font-family: inherit;
    line-height: inherit;
  }

  pre code::before {
    content: none;
  }

  pre code::after {
    content: none;
  }

  table {
    text-indent: 0;
    border-color: inherit;
    border-collapse: collapse;
    width: 100%;
    table-layout: auto;
    text-align: left;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  thead {
    border-bottom-width: 1px;
    border-bottom-color: var(--prose-th-borders);
  }

  thead th {
    color: var(--prose-headings);
    font-weight: 600;
    vertical-align: bottom;
  }

  tbody tr {
    border-bottom-width: 1px;
    border-bottom-color: var(--prose-td-borders);
  }

  tbody tr:last-child {
    border-bottom-width: 0;
  }

  tbody td {
    vertical-align: baseline;
  }

  tfoot {
    border-top-width: 1px;
    border-top-color: var(--prose-th-borders);
  }

  tfoot td {
    vertical-align: top;
  }
`;

export const proseSizing = css`
  ${lineHeight}
  font-size: .9em;

  .lead {
    font-size: 1.25em;
    margin-top: 1.2em;
    margin-bottom: 1.2em;
  }

  .small {
    font-size: 0.75em;
  }

  img,
  video {
    max-width: 100%;
    height: auto;
  }

  p {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
  }

  blockquote {
    margin-top: 1.6em;
    margin-bottom: 1.6em;
    padding-left: 1em;
  }

  h1 {
    font-size: 2.25em;
    margin-top: 0;
    margin-bottom: 0.9em;
    line-height: 1.11;
  }

  h2 {
    font-size: 1.5em;
    margin-top: 2em;
    margin-bottom: 1em;
    line-height: 1.33;
  }

  h3 {
    font-size: 1.25em;
    margin-top: 1.6em;
    margin-bottom: 0.6em;
  }

  h4,
  h5,
  h6 {
    font-size: 1em;
    margin-top: 1.5em;
    margin-bottom: 0.5em;
    line-height: 1.5;
  }

  img {
    display: inline-block;
    margin-top: 2em;
    margin-bottom: 2em;
  }

  .gatsby-image-wrapper img {
    margin-top: 0;
    margin-bottom: 0;
  }

  video {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  figure {
    margin-top: 2em;
    margin-bottom: 2em;
  }

  figure > * {
    margin-top: 0;
    margin-bottom: 0;
  }

  figcaption {
    font-size: 0.875em;
    line-height: 1.45;
    margin-top: 0.8em;
  }

  code {
    font-size: 0.875em;
  }

  h2 code {
    font-size: 0.875em;
  }

  h3 code {
    font-size: 0.9em;
  }

  pre {
    font-size: 0.875em;
    line-height: 1.7;
    margin-top: 1.7em;
    margin-bottom: 1.7em;
    border-radius: 0.8rem;
    padding-top: 0.8em;
    padding-right: 1.14em;
    padding-bottom: 0.8em;
    padding-left: 1.14em;
  }

  ol {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
  }

  ul {
    margin-top: 1.25em;
    margin-bottom: 1.25em;
    padding-left: 1.625em;
  }

  li {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  ol > li {
    padding-left: 0.375em;
  }

  ul > li {
    padding-left: 0.375em;
  }

  > ul > li p {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  ul-li:first-child {
    margin-top: 1.25em;
  }

  ul-li:last-child {
    margin-bottom: 1.25em;
  }

  ol-li:first-child {
    margin-top: 1.25em;
  }

  ol-li:last-child {
    margin-bottom: 1.25em;
  }

  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }

  hr {
    margin-top: 3em;
    margin-bottom: 3em;
  }

  hr + * {
    margin-top: 0;
  }

  h2 + * {
    margin-top: 0;
  }

  h3 + * {
    margin-top: 0;
  }

  h4 + *,
  h5 + *,
  h6 + * {
    margin-top: 0;
  }

  table {
    font-size: 0.875em;
    line-height: 1.71;
  }

  thead th {
    padding-right: 0.57em;
    padding-bottom: 0.57em;
    padding-left: 0.57em;
  }

  thead-th:first-child {
    padding-left: 0;
  }

  thead-th:last-child {
    padding-right: 0;
  }

  tbody td,
  tfoot td {
    padding-top: 0.57em;
    padding-right: 0.57em;
    padding-bottom: 0.57em;
    padding-left: 0.57em;
  }

  tbody-td-first-child-tfoot-td:first-child {
    padding-left: 0;
  }

  tbody-td-last-child-tfoot-td:last-child {
    padding-right: 0;
  }

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }
`;

export const prose = css`
  ${proseVars}
  .prose {
    ${proseBase}
    ${proseSizing}
  }
`;
