import { rgba } from 'polished';
import { createGlobalStyle } from 'styled-components';

import { mediaStyles } from '../../components/2020/layout/Media';

import { prose } from './prose';
import { reset } from './reset';
import { theme } from './theme';
import { fontSizeVars, spaceVars } from './typography';

export const GlobalStyles = createGlobalStyle`
  ${reset}
  ${mediaStyles}
  ${fontSizeVars}
  ${spaceVars}
  ${prose}

  body, html, #___gatsby, #gatsby-focus-wrapper {
    width: 100%;
    height: 100%;
  }

  body {
    color: ${theme.colors.dark};
    background-color: ${theme.colors.white};

    font-family: 'Graphik', Helvetica, sans-serif;
    font-size: ${theme.fontSizes.m};

    -webkit-overflow-scrolling: touch;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  * {
    box-sizing: border-box;
    max-width: ${theme.space.measure};
  }

  *:focus-visible {
    outline: none;
    box-shadow: inset 0 0 0 2px ${rgba(theme.colors.brand, 0.7)};
  }

  /* All other elements will have theme.space.measure max-width as a sensible default */
  html, body, iframe, div, canvas, header, table, nav, main, section, form, footer {
    max-width: none;
  }

  h1, h2, h3, h4, h5, h6, p, figure, blockquote, figcaption {
    margin: 0;
  }

  ul {
    list-style: none;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  img {
    display: block;
    max-width: 100%;
    border-radius: ${theme.borderRadius.s};
  }

  button {
    cursor: pointer;
    color: inherit;
    background-color: inherit;
    border-color: inherit;
    border-style: none;
    padding: 0;
  }
`;
